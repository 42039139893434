<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">

        <el-col :span="4">
          <el-select v-model="form.shopId" placeholder="选择店铺" clearable>
            <el-option
                v-for="item in shopIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="3">
          <el-button type="primary" @click="queryWarningOrder">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <br>
    <el-collapse v-model="activeNames">
      <el-collapse-item v-for="(item,index) in warningOrderList" :title="item.title" :name="index" :key="index" :class="item.colorValue" disabled>
        <el-row>
          <el-col :span="5">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("订单总数")' placement="top-start">
              <p>订单总数</p>
            </el-tooltip>
            <p>{{ item.orderCount }}</p>
          </el-col>
          <el-col :span="5">
            <el-tooltip class="item" effect="dark" :content='getAnnotation(item.titleOne)' placement="top-start">
              <p>{{item.titleOne}}</p>
            </el-tooltip>
            <p>{{ item.normalOrder }}</p>
          </el-col>
          <el-col :span="5">
            <el-tooltip class="item" effect="dark" :content='getAnnotation(item.titleTwo)' placement="top-start">
              <p >{{ item.titleTwo }}</p>
            </el-tooltip>
            <p>{{ item.warningOrder }}</p>
          </el-col>
          <el-col :span="5">
            <el-tooltip class="item" effect="dark" :content='getAnnotation(item.titleThree)' placement="top-start">
              <p>{{ item.titleThree }}</p>
            </el-tooltip>
            <p>{{ item.proportion+"%" }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("未预警")' placement="top-start">
              <p :style="{fontSize:'25px',color:item.isWarningValue==='未预警'?'':item.color}">{{item.isWarningValue}}</p>
            </el-tooltip>
            <p>&nbsp;</p>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "warning-order-analysis",
  data(){
    return{
      form:{
        shopId:"",
        tenantCrop:localStorage.getItem("tenantCrop")
      },
      activeNames:[0,1,2,3],
      shopIdArray:[],
      warningOrderList:[],
      annotations:[],
    }
  },
  created() {
    this.queryWarningOrder();
    this.queryShopIdArray();
    this.queryAnnotations();
  },
  methods:{
    queryWarningOrder(){
      this.$axios({
        method:"GET",
        url:"/shopReports/queryWarningOrder",
        params:{
          shopId:this.form.shopId,
          tenantCrop:this.form.tenantCrop,
        }
      }).then(response=>{
        response.data.data.map(k=>{
          k.proportion=((k.normalOrder/k.orderCount)*100).toFixed(2);
          k.proportion=isNaN(k.proportion)?"100":k.proportion;
          k.color=k.warningColor==="黄色"?"#b8a53d":"red";
          k.colorValue=k.warningColor==="黄色"?"yellow":"red";
          k.title=k.warningColor+k.warningType+"\u00a0\u00a0\u00a0\u00a0\u00a0预警天数 : "+k.warningDay
              +"\u00a0\u00a0\u00a0\u00a0预警值 : "+k.warningValue+"%";
          k.titleOne=k.warningType.includes("售后")?"售后阶段订单数":"已约取件阶段订单数";
          k.titleTwo=k.warningType.includes("售后")?"新订单阶段订单数":"未约取件阶段订单数";
          k.titleThree=k.warningType.includes("售后")?"进入售后阶段比率":"已约取件阶段比率";
          k.isWarningValue=Number(k.proportion)<Number(k.warningValue)?k.warningColor+"预警":"未预警"
          k.isWarningValueColor=k.isWarningValue!=="未预警"?'style="font-size:25px;color:'+k.color+'"':'style="font-size:25px"';
          return k;
        })
        this.warningOrderList=response.data.data;
      })
    },
    //查询店铺
    queryShopIdArray() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data)
      })
    },
    //查询注解
    queryAnnotations() {
      this.$commUtils.queryAnnotation(this.$route.fullPath).then(response => {
        this.annotations = response.data.data;
      })
    },
    //查询对应字段的注解
    getAnnotation: function (field1) {
      for(let i  = 0; i <this.annotations.length; i++){
        if(this.annotations[i].field === field1){
          return this.annotations[i].annotation;
        }
      }},
  },
}
</script>

<style scoped>
/deep/ .yellow .el-collapse-item__header {
  color:#b8a53d;
  font-size: 18px;
}
/deep/ .red .el-collapse-item__header {
  color:red;
  font-size: 18px;
}
p{
  margin: 3% 3%;
  font-size: 20px;
  font-weight: 500;
  font-family: 'SimSun-ExtB',serif;
}
</style>